var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":_vm.cols,"sm":_vm.sm,"md":_vm.md,"lg":_vm.lg,"xl":_vm.xl}},[_c('ValidationProvider',{attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"clearable":_vm.clearable,"dense":_vm.dense,"disabled":_vm.disabled,"error-messages":errors,"filter":_vm.customFilter,"hint":_vm.hint,"items":_vm.items,"label":_vm.label || _vm.name,"loading":_vm.loading,"multiple":_vm.multi,"persistent-hint":_vm.persistentHint,"readonly":_vm.readonly,"required":_vm.required,"search-input":_vm.search,"hide-details":"","filled":"","open-on-clear":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.onInput,"click:clear":_vm.prefill},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Nessun dispositivo. ")])],1)]},proxy:true},(_vm.select && _vm.select.notes)?{key:"prepend-inner",fn:function(){return [_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('h3',{staticClass:"mb-3"},[_vm._v("Note")]),_c('p',{staticClass:"white-space-pre-line"},[_vm._v(" "+_vm._s(_vm.select.notes)+" ")])])],1)],1)]},proxy:true}:null,{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.brand)+" "+_vm._s(item.model)+" ")]),(item.serial_code)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.serial_code)+" ")]):_vm._e(),(item.imei)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.imei)+" ")]):_vm._e()],1)]}}],null,true),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }