<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-autocomplete
        v-model="select"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :error-messages="errors"
        :filter="customFilter"
        :hint="hint"
        :items="items"
        :label="label || name"
        :loading="loading"
        :multiple="multi"
        :persistent-hint="persistentHint"
        :readonly="readonly"
        :required="required"
        :search-input.sync="search"
        hide-details
        filled
        open-on-clear
        return-object
        @input="onInput"
        @click:clear="prefill"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title> Nessun dispositivo. </v-list-item-title>
          </v-list-item>
        </template>
        <template v-if="select && select.notes" v-slot:prepend-inner>
          <v-menu
            open-on-hover
            bottom
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-information
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-text>
                <h3 class="mb-3">Note</h3>
                <p class="white-space-pre-line">
                  {{ select.notes }}
                </p>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.brand }} {{ item.model }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.serial_code">
              {{ item.serial_code }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.imei">
              {{ item.imei }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { customFilterFromProps } from '@utils/helper-functions.js'

export default {
  name: 'FormCustomersAsset',
  components: {},
  props: {
    asset_id: {
      default: null,
      type: String,
    },
    clearable: {
      default: false,
      type: Boolean,
    },
    customer_id: {
      default: null,
      type: String,
    },
    customer_user_id: {
      default: null,
      type: String,
    },
    associated_customer_user_id: {
      default: null,
      type: String,
    },
    associated_customer_location_id: {
      default: null,
      type: String,
    },
    onlyPrefill: {
      type: Boolean,
      default: false,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Dispositivo',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: null,
      required: false,
      type: [String, Object],
    },
    cols: {
      default: 12,
      type: [String, Number],
    },
    sm: {
      default: 6,
      type: [String, Number],
    },
    md: {
      default: 3,
      type: [String, Number],
    },
    lg: {
      default: 2,
      type: [String, Number],
    },
    xl: {
      default: 2,
      type: [String, Number],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data() {
    return {
      loading: false,
      search: null,
      select: null,
      firstRun: true,
    }
  },
  computed: {
    ...mapGetters('customersAssets', {
      items: 'getListDropdown',
    }),
  },
  watch: {
    customer_id: {
      immediate: true,
      handler: 'prefill',
    },
    customer_user_id: {
      immediate: true,
      handler: 'prefill',
    },
    associated_customer_user_id : {
      immediate: true,
      handler: 'checkUserLocation',
    },
    associated_customer_location_id : {
      immediate: true,
      handler: 'checkUserLocation',
    },
    search(val) {
      if (this.associated_customer_user_id || this.associated_customer_location_id) this.checkUserLocation()
      if (!this.onlyPrefill && val && this.select !== this.asset_id) {
        if (val !== this.select) {
          this.querySelections(val)
        }
      }
    },
  },
  async mounted() {
    await this.prefill()
  },
  beforeDestroy() {
    this.clearList()
  },
  methods: {
    ...mapActions('customersAssets', {
      searchAsset: 'getDropdownList',
    }),
    ...mapMutations('customersAssets', {
      clearList: 'RESET_LIST',
    }),

    checkUserLocation() {
      const checkResult = {
        sameUser: true,
        sameLocation: true
      }
      if (this.select) {
        const selectedAsset = this.items.find((asset) => asset.id === this.select);
        if (selectedAsset) {
          if (this.associated_customer_user_id && (selectedAsset.customer_user_id !== this.associated_customer_user_id)) {
            checkResult.sameUser = false
          }
          if (this.associated_customer_location_id && (selectedAsset.customer_location_id !== this.associated_customer_location_id)) {
            checkResult.sameLocation = false
          }
        }
      }
      this.$emit('check-user-location', checkResult)
    },

    async prefill() {
      if (!this.customer_id) return this.clearList()

      this.loading = true

      const filters = {}

      if (!this.onlyPrefill && this.asset_id) filters.id = this.asset_id
      if (this.customer_id) filters.customer_id = this.customer_id
      if (this.customer_user_id)
        filters.customer_user_id = this.customer_user_id

      await this.searchAsset({
        filters,
      })

      if (this.asset_id && this.onlyPrefill) {
        this.select = this.asset_id
      } else if (!this.onlyPrefill && this.firstRun && this.items.length === 1) {
        this.select = this.items[0].value
        this.firstRun = false
      }

      this.$emit('input', this.select)
      this.loading = false
    },
    customFilter: customFilterFromProps([
      'model',
      'brand',
      'imei',
      'serial_code',
    ]),
    async querySelections(query) {
      // if (query.length < 3) return
      let parsedQuery = query

      /*
      When selecting an option it fires a query with the text value
      Here we extract the imei from the whole, so it can still return results
      */
      const match = query.match(/^[\S\s]*\((\S+)\)$/) // Coulda used lookbehind but it's poorly supported
      if (match) parsedQuery = match[1]

      this.loading = true

      const filters = {
        query: parsedQuery,
      }

      if (this.customer_id) filters.customer_id = this.asset_id

      if (this.customer_user_id)
        filters.customer_user_id = this.customer_user_id

      await this.searchAsset({
        filters,
      })

      this.loading = false
    },
    onInput: debounce(function(item) {
      this.$emit('input', item?.value)
      this.$emit('asset', item)
    }, 250),
  },
}
</script>

<style>
.white-space-pre-line {
  white-space: pre-line;
}
</style>
