<template>
  <FormItem
    v-model="id"
    type="select"
    :values="list"
    name="Sottoscrizione"
    :rules="rules"
    :hint="hint"
    persistent-hint
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :dense="dense"
    :disabled="disabled"
    :clearable="clearable"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Status: <strong>{{ item.status }}</strong>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Scadenza: {{ item.date_expiration | moment('DD/MM/YYYY') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </FormItem>
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'CustomersSubscriptionsSelect',
  components: {
    FormItem,
  },
  props: {
    customerid: {
      type: String,
      default: null,
    },
    customer_user_id: {
      type: String,
      default: null,
    },
    plans_subscription_id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 2,
    },
    xl: {
      type: [String, Number],
      default: 2,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      },
    },
    ...mapState('plansSubscriptions', ['list']),
  },
  watch: {
    customerid: {
      immediate: true,
      handler: 'populate',
    },
    customer_user_id: {
      immediate: true,
      handler: 'populate',
    },
    list: {
      immediate: true,
      handler: 'onMounted',
    },
  },
  mounted() {
    this.populate()
  },
  beforeDestroy() {
    this.resetList()
  },
  methods: {
    ...mapActions('plansSubscriptions', ['getDropdownList']),
    ...mapMutations('plansSubscriptions', {
      resetList: 'RESET_LIST',
    }),
    async populate() {
      if (this.customerid || this.customer_user_id) {
        await this.getDropdownList({
          filters: {
            customer_id: this.customerid,
            customer_user_id: this.customer_user_id,
          },
        })
      }
    },
    onMounted() {
      if (this.autoSelect) {
        if (this.list.length) {
          this.id = this.list[0].value
        } else {
          this.id = null
        }
      }
    },
  },
}
</script>
